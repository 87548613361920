import React, {useEffect} from 'react';
import '@styles/tyc.scss';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useLocation } from "react-router-dom";

const TyC = () => {
    const location = useLocation();
    useEffect(() => {
        window.dataLayer.push({
            event: "page_view"
        })
    }, [location]);

	return (
        <div className="tyc">
            <header className="header"><Header/></header>
            <div className='tycContainer'>
                <div className='tycTitle'>Términos y Condiciones</div>
                <div className='tyctext'>
                    <p>
                        Aquí se detallan los términos y condiciones para el uso de la Plataforma de Firulife tanto
                        para personas como para empresas. Agradecemos se de lectura detenidamente. Las
                        personas y empresas al suscribir los términos y condiciones del servicio, aceptan
                        libremente los enunciados y se comprometen a cumplirlos en todo su contenido.
                        De no estar de acuerdo, solicitamos expresar por correo electrónico su desafiliación
                        y se abstenga del uso de nuestros servicios.
                    </p>
                    <p className='tycSubTitle'>1. Acceso al sitio Web</p>
                    <p>
                        El acceso y utilización del Sitio Web no exige la previa suscripción o registro del Usuario.
                        Sin embargo, es posible que la utilización de algunos de los Servicios ofrecidos a través del Sitio Web requiera la suscripción o registro del Usuario y/o el pago de un precio.
                    </p>
                    <p>
                        El Usuario se compromete a utilizar el Sitio Web de conformidad con estas Condiciones Generales,
                        las leyes aplicables conforme con la moral y buenas costumbres.
                    </p>
                    <p>
                        El Usuario se obliga a abstenerse de utilizar el Sitio Web con fines o efectos ilícitos o
                        ilegales, contrarios a lo establecido en estas Condiciones Generales, a la ley peruana,
                        lesivos de los derechos e intereses de terceros (incluyendo clientes de Firulife y a
                        los distintos Usuarios del Sitio Web), o que de cualquier forma puedan dañar, inutilizar,
                        sobrecargar o deteriorar el Sitio Web o impedir la normal utilización del Sitio Web por parte
                        de los Usuarios
                    </p>
                    <p>
                        Reglas generales: Los Usuarios tienen prohibido utilizar el Sitio Web: (i) intentando incumplir, incumpliendo o violando
                        la legislación aplicable; (ii) de forma que se infrinjan derechos de terceros o se viole la confidencialidad, honor,
                        privacidad, imagen u otros derechos de terceros, incluyendo clientes de Firulife; (iii) para enviar correos sin autorización
                        expresa del propietario de los datos, lo cual incluye cualquier tipo de comunicación, promociones, y/o publicidad de
                        productos o servicios, SPAM o propaganda política, de acuerdo a lo establecido en la Ley 29751 - el Código de Protección y
                        Defensa del Consumidor o la norma que sustituya a la misma.
                    </p>
                    <p>
                        Reglas de Seguridad del Sitio Web: Los Usuarios tienen prohibido violar o intentar violar la seguridad del Sitio Web, incluyendo
                        pero no limitándose a: (i) el acceso a datos que no estén destinados a tal usuario o entrar en un servidor o cuenta cuyo acceso
                        no está autorizado al Usuario, (ii) evaluar o probar la vulnerabilidad de un sistema o red, o violar las medidas de seguridad o
                        identificación sin la adecuada autorización, (iii) intentar impedir el Servicio a cualquier Usuario, anfitrión o red, incluyendo,
                        pero sin limitación, mediante el envío de virus al Sitio Web, o mediante saturación o ataques de denegación de Servicio, (iv) enviar
                        correos no pedidos, incluyendo promociones y/o publicidad de productos o servicios, o (v) falsificar cualquier cabecera de paquete
                        TCP/IP y/u otro tipo de paquete de datos, o cualquier parte de la información de cabecera de cualquier correo electrónico o en mensajes
                        de foros de debate
                    </p>
                    <p>
                        El Sitio Web sólo podrá ser utilizado con fines lícitos, para acceder a información referida a los Servicios disponibles a través del mismo.
                        Firulife prohíbe específicamente cualquier utilización del Sitio Web o de los Servicios para:
                    </p>
                    <p>
                        (A) Anunciar datos incompletos, falsos o inexactos.
                        (B) Registrar más de una cuenta correspondiente a un mismo usuario.
                        (C) Usar cualquier mecanismo para impedir o intentar impedir el adecuado funcionamiento de este Sitio Web y/o cualquier actividad
                        que se esté realizando en el Sitio Web.
                        (D) Revelar o compartir su usuario y contraseña con terceras personas, o usar su contraseña para propósitos no autorizados.
                        (E) El uso o intento de uso de cualquier máquina, software, herramienta, agente u otro mecanismo para navegar o buscar en el Sitio Web
                        que sean distintos a las herramientas de búsqueda provistos por Firulife.
                        (F) Intentar descifrar, descompilar u obtener el código fuente de cualquier programa de software del Sitio Web.
                    </p>
                    <p>
                        Firulife se reserva el derecho de suspender o dar de baja cualquier aviso clasificado (gratuito o de costo), cuenta o Usuario que, a exclusivo criterio de Firulife, no cumpla con la legislación vigente, los estándares definidos en estas Condiciones Generales o con las políticas vigentes de Firulife, sin que ello genere derecho a resarcimiento alguno y sin responsabilidad para Firulife.
                    </p>
                    <p className='tycSubTitle'>2. Uso de geolocalización</p>
                    <p>
                        Se solicitará el uso de la geolocalización para brindar una mejor experiencia ante las busquedas de servicios para mascotas,
                        recordemos que el uso de la geolicalización permite poder listar los establecimientos cercanos a tu ubicación. Para mayor
                        información sobre este punto, puede revisar la Política de Privacidad.
                    </p>
                    <p className='tycSubTitle'>3. Datos personales de los usuarios</p>
                    <p>
                        El uso de un login con google facilita tanto el login como el registro, recordemos que los datos solicitados, son datos
                        del perfi lde google, como nombre completo, correo e imagen de google si hubiera.
                        Estos datos nos permitirán poder brindar un mejor servicio en la plataforma, ya que ayudarán a evitar el ingreso de usuarios
                        maliciosos dentro de la plataforma.
                    </p>
                    <p>
                        De contar con su consentimiento, Firulife se reserva el derecho de ofrecerle servicios y productos de terceros basados en
                        las preferencias que Usted indicó al momento de registrarse o en cualquier momento posterior; tales ofertas pueden ser
                        efectuadas por Firulife directamente o por dichos terceros, para lo cual Firulife podrá compartir con dichos terceros la información
                        proporcionada por el Usuario de conformidad con la Política de Privacidad de Firulife.
                    </p>
                    <p>
                        El tratamiento de datos sensibles y la información de los usuarios solo será almacenada en nuestro banco de datos
                        personales cuando se hagan llegar por su titular y previa validación de la información, para validar su consentimiento.
                    </p>
                    <p>
                        La información brindada por los usuarios se rige a la ley vigente N° 29733, Ley de Protección de Datos Personales y su reglamento, aprobado mediante el Decreto Supremo N° 003-2013-JUS.
                        En ese sentidop usamos la información para poder realizar
                    </p>
                    <p className='tycSubTitle'>4. Uso de la web para menores de edad </p>
                    <p>
                        Firulife es un plataforma que brinda servicios relacionados al bienestar de su mascota, por lo que el uso de la plataforma
                        tiene algunas secciones donde podrás hacer compras y pagos por internet. En ese sentido, el uso de la plataforma es
                        exclusivo de mayores de edad. Queda prohibida la utilización del Sitio Web y/o de los Servicios ofrecidos a través del mismo por personas que carezcan
                        de capacidad legal para contratar o menores de edad según la legislación. En caso que ello ocurra, los menores de edad o incapaces deben obtener
                        previamente permiso de sus padres, tutores o representantes legales, quienes serán considerados responsables de todos los actos realizados por
                        las personas a su cargo.
                    </p>
                    <p>
                        El Sitio Web es una comunidad en constante evolución que incluye diversos usuarios con diferentes experiencias y antecedentes.
                        A pesar que Firulife puede monitorear y tomar acción sobre la conducta inapropiada de otros usuarios o terceros, en el Sitio Web o el acceso
                        o utilización de cualquiera de los Servicios ofrecidos, incluidas salas de chat y foros públicos, es posible que en cualquier momento pueda
                        presentarse un lenguaje u otros materiales accesibles en o a través de tales que puedan ser inapropiados para menores de edad u ofensivos
                        para otros usuarios. Las presentes Condiciones Generales señalan normas de conducta para los usuarios, pero Firulife no puede
                        garantizar que el resto de usuarios cumplan con los mismos.
                    </p>
                    <p className='tycSubTitle'>5. Garantía en la prestación de servicios y funcionamiento de la web </p>
                    <p>
                        Firulife no garantiza la disponiblidad constante y correcto funcionamiento
                        de nuestra web. Es posible que al registrar información se presenten errores que se
                        corregirán en un plazo razonable. Asimismo, no garantizamos que nuestra web este
                        libre de virus u otros daños a sus equipos.
                    </p>
                    <p>
                        Firulife cuenta con correos autorizados o el canal de nuestra web como los
                        únicos medios de atención de nuestros servicios, tanto para personas y
                        empresas. Por ello, no se hace responsable de los daños ocasionados por información enviada a otros
                        medios distintos a los acreditados en nuestro sitio web que sean usados a nombre
                        de Firulife o que bajo este medio se usen por terceros usando el nombre de
                        Firulife.
                    </p>
                    <p className='tycSubTitle'>6. Responsabilidad del usuario </p>
                    <p>
                        El Usuario declara y acepta que el uso del Sitio Web sus Servicios y los contenidos se efectúa bajo su única y
                        exclusiva responsabilidad y riesgo comercial. En caso de que un Usuario detecte un aviso que, a su entera discreción, resulte engañoso, fraudulento, incompleto, falso y/o
                        inexacto, el Usuario tendrá la obligación de reportarlo a Firulife usando el coreo de firufirulife@gmail.com con el asunto de "usuario fraudlento"
                    </p>
                    <p className='tycSubTitle'>7. Exclusion de garantías y de responsabilidad</p>
                    <p>
                        Atento al estado de la técnica y a la estructura y funcionamiento de las redes, el Sitio Web no puede
                        confirmar que cada Usuario es quien dice ser.
                    </p>
                    <p>
                        Firulife se reserva el derecho de modificar de manera unilateral total o
                        parcial los presentes Términos y Condiciones en cualquier momento, la misma que
                        tendrá validez plena desde el día siguiente de haberse comunicado y notificado
                        por nuestro sitio web y correo electrónico. Cabe precisar que la comunicación por
                        nuestro sitio web o envio de correo electrónico implica conocimiento y aceptación
                        de los Términos y Condiciones.
                    </p>
                    <p>
                        La nulidad de alguna de las clausulas de los términos y condiciones no afecta la
                        validez de las otras. Estas seguiran manteniendo su vigencia plena. Asimismo, los
                        terminos y condiciones no contradicen las garantias previstar en codigo de
                        protección del consumidor.
                    </p>
                    <p className='tycSubTitle'>8. Vínculos a otros sitios</p>
                    <p>
                        El Sitio Web contiene vínculos a otros sitios de Internet. Firulife no respalda los contenidos de estos sitios web.
                        Firulife no es responsable del contenido de, ni los servicios o productos ofrecidos o proporcionados por, dichos sitios web de
                        terceros y no hace ninguna afirmación relativa al contenido o su exactitud en estos sitios web de terceros. Si Usted decide acceder a
                        sitios web de terceras partes vinculados, lo hace a su propio riesgo.
                    </p>
                    <p className='tycSubTitle'>9. Cancelación</p>
                    <p>
                        FiruLife tiene la facultad, y no la obligación, de emitir advertencias, suspensiones temporales y cancelaciones permanentes (baja) de los
                        Usuarios registrados por infracciones a las presentes Condiciones Generales, y/o cualquier aviso, reglamento de uso e instrucción puestos
                        en conocimiento del Usuario por FiruLife.
                    </p>
                    <p>
                        Sin perjuicio de lo indicado en el punto anterior, Firulife se reserva el derecho, a su exclusivo criterio, de emplear todos los medios
                        legales, acciones y recursos a su alcance en caso que Usted infrinja cualquiera de estas Condiciones Generales.
                    </p>
                    <p>
                        Los Usuarios podrán cancelar los Servicios que hubieren contratado en cualquier momento y a su entera discreción, debiendo dar previa
                        notificación a FiruLife. Sin perjuicio de ello, no tendrán derecho a reembolso alguno de los Servicios contratados.
                    </p>
                    <p className='tycSubTitle'>10. Indemnización</p>
                    <p>
                        Usted acepta defender, indemnizar y sacar en paz y a salvo a Firulife y a sus filiales y subsidiarias, y a sus respectivos consejeros,
                        directivos, empleados, representantes, agentes y asesores, frente a cualquier cargo, acción judicial o extrajudicial, demanda de cualquier
                        naturaleza, denuncia penal, reclamación o responsabilidad, pena, multa o sanción pecuniaria, de cualquier naturaleza y como quiera que se
                        denomine, que surjan de o estén de alguna manera relacionados con (a) cualquier incumplimiento de las presentes Condiciones Generales o a
                        la legislación peruana; (b) la falsedad o incorrección de cualquier información, publicada por el Usuario en el Sitio Web o por cuenta del
                        Usuario, incluyendo (pero sin estar limitado a) en relación con el (los) aununcio(s) y servicio(s) anunciados, la titularidad anunciante
                        sobre el servicio y/o anuncio, su facultad para venderlo o prestarlo, los datos proporcionados a Firulife para crear su cuenta en el
                        Sitio Web, y/o contenido de cualquier anuncio clasificado (ya sea gratuito o de costo); y (c) cualquier interacción con cualquier otro
                        Usuario o cualquier tercero. Dicha indemnización incluirá pero no está limitada a los gastos y honorarios razonables de abogados en los
                        que Firulife incurra al defenderse de cualquier proceso o procedimiento que se interponga en su contra.
                    </p>
                    <p className='tycSubTitle'>11. Duración</p>
                    <p>
                        Firulife de oficio puede suspender unilaterlamente las prestación de sus
                        servicios en cualquier momento dejando a salvo la resolución y solución de
                        obligaciones pendientes iniciadas antes de la decisión de suspender sus servicios.
                    </p>
                    <p className='tycSubTitle'>12. Firulife</p>
                    <p>
                        La denominación legal que adopta Firulife es: LECCRU EIRL, con RUC Nº 20601518105 y domicilio en Calle. Urb. Vruces mz D Lt 65, Nuevo Chimbote, Ancash, Perú. El correo electrónico para
                        recbar, recibir y verificar información, solicitudes y otros es firufirulife@gmail.com
                    </p>
                    <p>
                        Los Términos y Condiciones se desarrolan y tienen como marco las normativa de la
                        República del Perú. Las empresas se someten ante cualquier controversía sobre los
                        presenten términos y condiciones (interpretación o cumplimiento) a conciliación,
                        arbitrajes y la vía judicial a la jursidicción de los juzgados y tribunales de Lima
                        Cercado.
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <p>
                        Los Términos y Condiciones se actualizaron por última vez el 31 de marzo de 2024.
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
	);
}

export default TyC;